import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
	AiFillGithub,
	AiOutlineMail,
	// AiOutlineTwitter,
	AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
	let date = new Date();
	let year = date.getFullYear();
	return (
		<Container fluid className="footer">
			<Row>
				<Col md="4" className="footer-copywright">
					<span>Dedicated to creating impactful solutions</span>
				</Col>
				<Col md="4" className="footer-copywright">
					<span>Copyright © {year} Muhammad Habib Fery</span>
				</Col>
				<Col md="4" className="footer-body">
					<ul className="footer-icons">
						<li className="social-icons">
							<a
								href="https://github.com/muhammadhabibfery"
								style={{ color: "white" }}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="github"
							>
								<AiFillGithub />
							</a>
						</li>
						{/* <li className="social-icons">
							<a
								href="https://twitter.com/FeryLeonardo12"
								style={{ color: "white" }}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="twitter"
							>
								<AiOutlineTwitter />
							</a>
						</li> */}
						<li className="social-icons">
							<a
								href="https://www.linkedin.com/in/muhammadhabibfery/"
								style={{ color: "white" }}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="linkedin"
							>
								<FaLinkedinIn />
							</a>
						</li>
						<li className="social-icons">
							<a
								href="mailto:muhammadhabibfery@gmail.com"
								style={{ color: "white" }}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="mail"
							>
								<AiOutlineMail />
							</a>
						</li>
						<li className="social-icons">
							<a
								href="https://wa.me/6285281477748"
								style={{ color: "white" }}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="whatsapp"
							>
								<AiOutlineWhatsApp />
							</a>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
