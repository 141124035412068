import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";
import {
	AiFillGithub,
	AiOutlineMail,
	// AiOutlineTwitter,
	AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const About = () => {
	return (
		<Container fluid className="home-about-section" id="about">
			<Container>
				<Row>
					<Col md={8} className="home-about-description">
						<h1 style={{ fontSize: "2.6em" }}>
							LET ME <span className="yellow"> INTRODUCE </span>{" "}
							MYSELF
						</h1>
						<p className="home-about-body">
							Hi, my name is{" "}
							<span className="yellow">Muhammad Habib Fery </span>
							and I'm from{" "}
							<span className="yellow"> Jakarta, Indonesia.</span>
							<br />
							<br />
							Specialized in software development, especially web
							based development.
							<br />
							<br />
							As a<b className="yellow"> Full Stack Developer</b>,
							I enjoy tackling new challenges and continuously
							expanding my skillset.
							<br />
							<br /> I have experience in several programming
							languages and tech stack which I will explain in the
							skillset section
							<br />
							<br />I am also interested in building new
							<i>
								<b className="yellow">
									{" "}
									Web Technologies and Products,{" "}
								</b>
								as well as exploring areas related to
								<b className="yellow">
									{" "}
									Artificial Intelligence.
								</b>
							</i>
							<br />
						</p>
					</Col>
					<Col md={4} className="myAvtar">
						<Tilt>
							<img
								src={LaptopImg}
								className="img-fluid"
								alt="avatar"
							/>
						</Tilt>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="home-about-social">
						<h1>FIND ME ON</h1>
						<p>
							Please don't hesitate to reach out to me and connect
						</p>
						<ul className="home-about-social-links">
							<li className="social-icons">
								<a
									href="https://github.com/muhammadhabibfery"
									target="_blank"
									rel="noreferrer"
									className="icon-colour  home-social-icons"
									aria-label="github"
								>
									<AiFillGithub />
								</a>
							</li>
							{/* <li className="social-icons">
								<a
									href="https://twitter.com/FeryLeonardo12"
									target="_blank"
									rel="noreferrer"
									className="icon-colour  home-social-icons"
									aria-label="twitter"
								>
									<AiOutlineTwitter />
								</a>
							</li> */}
							<li className="social-icons">
								<a
									href="https://www.linkedin.com/in/muhammadhabibfery/"
									target="_blank"
									rel="noreferrer"
									className="icon-colour  home-social-icons"
									aria-label="linkedin"
								>
									<FaLinkedinIn />
								</a>
							</li>
							<li className="social-icons">
								<a
									href="mailto:muhammadhabibfery@gmail.com"
									target="_blank"
									rel="noreferrer"
									className="icon-colour home-social-icons"
									aria-label="mail"
								>
									<AiOutlineMail />
								</a>
							</li>
							<li className="social-icons">
								<a
									href="https://wa.me/6285281477748"
									target="_blank"
									rel="noreferrer"
									className="icon-colour home-social-icons"
									aria-label="whatsapp"
								>
									<AiOutlineWhatsApp />
								</a>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default About;
