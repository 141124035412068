import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";
import Particle from "../components/Particle";
import simka from "../assets/projects/simka.png";
import hrSystem from "../assets/projects/hr-system.png";
import laracommerce from "../assets/projects/api laracommerce.png";
import travel from "../assets/projects/travel.png";
import jersey from "../assets/projects/jersey.png";
import rentcars from "../assets/projects/rentcars.png";

const Projects = () => {
	return (
		<Container fluid className="project-section">
			<Particle />
			<Container>
				<h1 className="project-heading">
					Recent Top <strong className="yellow">Works </strong>
				</h1>
				<p style={{ color: "white" }}>
					Here are a few projects I've worked on recently.
				</p>
				<Row
					style={{ justifyContent: "center", paddingBottom: "10px" }}
				>
					<Col md={4} className="project-card">
						<ProjectCard
							imgPath={simka}
							title="SIMKA (Sistem Informasi Manajemen Kabinet RI)"
							description1="A web based mailing system used by the internal Indonesian government."
							// description2="Tech stack: PHP (Laravel), PostgreSQL, Redis, PHPUnit, React, Vite."
							description2="Tech stack: -"
							demoLink="https://simka.muhammadhabibfery.com"
						/>
					</Col>
					<Col md={4} className="project-card">
						<ProjectCard
							imgPath={hrSystem}
							title="HR System"
							description1="A web based HR System used by the internal company."
							description2="Tech stack: PHP (Native), MYSQL, React, Vite."
							demoLink="https://hr-system.muhammadhabibfery.com/"
						/>
					</Col>
					<Col md={4} className="project-card">
						<ProjectCard
							imgPath={laracommerce}
							title="Laracommerce"
							description1="REST API for E-Commerce platform with admin panel integration."
							description2="Tech stack: PHP (Laravel), MySQL, Algolia, PHPUnit, Livewire, Filament."
							ghLink="https://github.com/muhammadhabibfery/laracommerce-api"
							demoLink="https://api-laracommerce.muhammadhabibfery.com/"
						/>
					</Col>
					<Col md={4} className="project-card">
						<ProjectCard
							imgPath={travel}
							title="RelaxArc Travel"
							description1="A web based travel platform with admin panel integration."
							description2="Tech stack: PHP (Laravel), MySQL, PHPUnit, Livewire, Filament, Bootstrap, Sass."
							ghLink="https://github.com/muhammadhabibfery/relaxarc-travel"
							demoLink="https://relaxarc-travel.muhammadhabibfery.com/"
						/>
					</Col>
					<Col md={4} className="project-card">
						<ProjectCard
							imgPath={jersey}
							title="Jersey Avenue"
							description1="A web based with interactive SPA platform with admin panel integration."
							description2="Tech stack: PHP (Laravel), MySQL, PHPUnit, Livewire, Filament, Tailwind."
							ghLink="https://github.com/muhammadhabibfery/jersey-avenue"
							demoLink="https://jersey-avenue.muhammadhabibfery.com/"
						/>
					</Col>
					<Col md={4} className="project-card">
						<ProjectCard
							imgPath={rentcars}
							title="Rent Cars"
							description1="A web based car rental managent platfiorm with admin panel integration."
							description2="Tech stack: PHP (Laravel), MySQL, PHPUnit, Bootstrap, Sass."
							ghLink="https://github.com/muhammadhabibfery/rent-cars"
							demoLink="https://rent-cars.muhammadhabibfery.com/"
						/>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default Projects;
