import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Container, Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import "./Contact.css";
import Toaster from "../Toaster";

const Contact = () => {
	const form = useRef();
	const [done, setDone] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isToaster, setIsToaster] = useState(false);
	const [toasterStatus, setToasterStatus] = useState(null);
	const [toasterStatusColor, setToasterStatusColor] = useState(null);
	const [toasterMessage, setToasterMessage] = useState(null);
	const [notDone, setNotDone] = useState(false);
	const [formData, setFormData] = useState({});

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		setDone(false);
		setNotDone(false);
	};

	const handleResetInputValue = () => {
		form.current.from_name.value = null;
		form.current.reply_to.value = null;
		form.current.subject.value = null;
		form.current.message.value = null;
	};

	const sendEmail = (e) => {
		e.preventDefault();
		setIsSubmitted(true);

		if (
			!formData.from_name ||
			!formData.reply_to ||
			!formData.subject ||
			!formData.message
		) {
			setNotDone(true);
			setIsSubmitted(false);
		} else {
			emailjs
				.sendForm(
					"service_g9eg12m",
					"template_ofpy64q",
					form.current,
					"RhVxBrNw-8H83aCim"
				)
				.then(
					(response) => {
						console.log("SUCCESS!", response.status, response.text);
						handleResetInputValue();
						setDone(true);
						setIsSubmitted(false);
						setIsToaster(true);
						setToasterStatusColor("green");
						setToasterStatus("Success");
						setToasterMessage(
							"Thanks for contacting me and be sure i have recieved your mail. If you are testing this functionality then i am confirming this thing working perfectly fine. If you have any serious question then i will reply. Also if you need me, you can contact me on Whatsapp or Linkedin."
						);
					},
					(err) => {
						console.log("FAILED...", err.status, err.text);
						setIsSubmitted(false);
						setIsToaster(true);
						setToasterStatusColor("red");
						setToasterStatus("Failed");
						err.status === 429
							? setToasterMessage(
									"Failed to send message, you or someone else just sent a message a moment ago. Please wait for the next 10 minutes."
							  )
							: setToasterMessage(
									"Failed to send message, please try again later."
							  );
					}
				);
		}
	};

	return (
		<Container style={{ paddingTop: "50px" }}>
			<Row>
				<Col md={6} className="c-left">
					<h1 className="c-left-title">Get in Touch</h1>
					<h1 className="yellow">Contact me</h1>
					{isToaster && (
						<>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									marginBottom: "15px",
								}}
							>
								<Toaster
									status={toasterStatus}
									statusColor={toasterStatusColor}
									message={toasterMessage}
									onClose={() => setIsToaster(false)}
								/>
							</div>
						</>
					)}
				</Col>
				<Col md={6} className="c-right">
					<form ref={form} onSubmit={sendEmail}>
						<input
							type="text"
							name="from_name"
							className="user"
							placeholder="Name"
							onChange={handleChange}
						/>
						<input
							type="email"
							name="reply_to"
							className="user"
							placeholder="Email"
							onChange={handleChange}
						/>
						<input
							type="text"
							name="subject"
							className="user"
							placeholder="Subject"
							onChange={handleChange}
						/>
						<textarea
							name="message"
							className="user"
							placeholder="Message"
							onChange={handleChange}
						/>
						{notDone && (
							<span className="not-done">
								Please, fill all the input field
							</span>
						)}
						<Button
							type="submit"
							className="button"
							disabled={isSubmitted}
						>
							{isSubmitted ? (
								<Spinner
									animation="border"
									variant="secondary"
								/>
							) : (
								<span style={{ color: "azure" }}>Send</span>
							)}
						</Button>
					</form>
				</Col>
			</Row>
		</Container>
	);
};

export default Contact;
