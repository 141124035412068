import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub, AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import "./Social.css";

const Social = () => {
	return (
		<Container style={{ padding: "30px" }}>
			<Row>
				<Col md={12} className="contact-social">
					<div className="contact-text">
						<h1>FIND ME ON</h1>
						<p>
							Please don't hesitate to reach out to me and connect
						</p>
					</div>
					<ul className="contact-social-links">
						<li className="contact-icons">
							<a
								href="https://github.com/muhammadhabibfery"
								target="_blank"
								rel="noreferrer"
								className="icon-color  contact-social-icons"
								aria-label="github"
							>
								<AiFillGithub />
							</a>
						</li>
						<li className="contact-icons">
							<a
								href="https://www.linkedin.com/in/muhammadhabibfery/"
								target="_blank"
								rel="noreferrer"
								className="icon-color  contact-social-icons"
								aria-label="linkedin"
							>
								<FaLinkedinIn />
							</a>
						</li>
						<li className="contact-icons">
							<a
								href="mailto:muhammadhabibfery@gmail.com"
								target="_blank"
								rel="noreferrer"
								className="icon-color  contact-social-icons"
								aria-label="mail"
							>
								<AiOutlineMail />
							</a>
						</li>
						<li className="contact-icons">
							<a
								href="https://wa.me/6285281477748"
								target="_blank"
								rel="noreferrer"
								className="icon-color  contact-social-icons"
								aria-label="whatsapp"
							>
								<AiOutlineWhatsApp />
							</a>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
};

export default Social;
