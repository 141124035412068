import React from "react";
import { Col, Row } from "react-bootstrap";
import {
	DiJavascript1,
	DiReact,
	DiNodejs,
	DiPhp,
	DiPostgresql,
	DiMysql,
	DiRedis,
} from "react-icons/di";
import {
	SiHtml5,
	SiLaravel,
	SiExpress,
	SiCss3,
	SiJest,
	SiAlpinedotjs,
	SiTailwindcss,
	SiBootstrap,
} from "react-icons/si";

const Techstack = () => {
	return (
		<Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
			<Col xs={3} md={2} className="tech-icons">
				<DiJavascript1 />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<DiPhp />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<DiNodejs />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiExpress />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiLaravel />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<DiPostgresql />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<DiMysql />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<DiRedis />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiJest />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiHtml5 />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiCss3 />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<DiReact />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiAlpinedotjs />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiTailwindcss />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiBootstrap />
			</Col>
		</Row>
	);
};

export default Techstack;
