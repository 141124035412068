import React from "react";
import { Col, Row } from "react-bootstrap";
import {
	SiVisualstudiocode,
	SiPostman,
	SiGitlab,
	SiGithub,
	SiGit,
	SiDocker,
	SiNginx,
	SiApache,
	SiCloudflare,
	SiAmazonaws,
} from "react-icons/si";

const Toolstack = () => {
	return (
		<Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
			<Col xs={3} md={2} className="tech-icons">
				<SiGit />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiGithub />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiGitlab />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiVisualstudiocode />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiDocker />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiPostman />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiNginx />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiApache />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiCloudflare />
			</Col>
			<Col xs={3} md={2} className="tech-icons">
				<SiAmazonaws />
			</Col>
		</Row>
	);
};

export default Toolstack;
