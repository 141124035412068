import React from "react";
import Toast from "react-bootstrap/Toast";

const Toaster = (props) => {
	return (
		<Toast
			onClose={props.onClose}
			delay={props.statusColor === "green" ? 22000 : 7500}
			autohide
		>
			<Toast.Header closeButton={false}>
				<strong className="me-auto">
					<span style={{ color: props.statusColor }}>
						{props.status}
					</span>
				</strong>
			</Toast.Header>
			<Toast.Body>{props.message}</Toast.Body>
		</Toast>
	);
};

export default Toaster;
